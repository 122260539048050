import { useQuery } from "@apollo/client";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { GET_OFFER_POSITIONS } from "../../../../api/graphql/position";
import { GET_SECTORS } from "../../../../api/graphql/sector";
import { useBreakpoint } from "../../../../hooks/useBreakpoint";
import { getOffers } from "../../../../redux/offers/offers.actions";
import { OffersActions } from "../../../../redux/offers/offers.slice";
import { Dispatch, StoreState } from "../../../../redux/store";
import { Breakpoint } from "../../../../types/enum.types";
import { GetOfferPositionsResponse } from "../../../../types/position.type";
import { GetSectorsResponse } from "../../../../types/sector.type";
import { NUMBER_OF_ITEMS_PER_PAGINATION } from "../../../../utils/constants";
import {
  formatSelectedFilters,
  pushFiltersToQueryParams,
} from "../../../../utils/functions.utils";
import { contractTypeMapping } from "../../../../utils/mapping";
import { OFFERS_PAGE } from "../../../../utils/pages";
import { slugifyText } from "../../../../utils/slugify.utils";
import { FlatSvg } from "../../../home/utils/styles";
import DropdownInput from "../../../Input/DropdownInput";
import SelectorInput from "../../../Input/SelectorInput";
import ContractTypes from "../../../profile/ProfileSettings/ContractTypes";
import OfferLocationInput from "../OfferLocationInput";

const OfferSearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  /* large  */
  @media screen and (max-width: 992px) {
    flex-direction: column;
    gap: 10px;
  }
`;

interface Props {
  version?: number;
  currentPage?: number;
  companySlug?: string;
}

const OfferSearchInputs: React.FC<Props> = ({
  version,
  currentPage,
  companySlug,
}) => {
  const breakpoint = useBreakpoint();
  const router = useRouter();
  const { filters } = useSelector((store: StoreState) => store.offers);
  const dispatch = useDispatch<Dispatch>();

  const { data: positions } =
    useQuery<GetOfferPositionsResponse>(GET_OFFER_POSITIONS);
  const { data } = useQuery<GetSectorsResponse>(GET_SECTORS);

  const availableData = useMemo(() => {
    if (!filters?.title || !positions?.getOfferPositions) return [];
    const title = filters.title.toLowerCase();
    return positions.getOfferPositions.filter((position) =>
      position.name.toLowerCase().includes(title)
    );
  }, [positions, filters.title]);

  const isMedium =
    breakpoint.includes(Breakpoint.XS) && !breakpoint.includes(Breakpoint.LG);

  const submitSearch = () => {
    pushFiltersToQueryParams(router, filters, OFFERS_PAGE);
    if (router.pathname + "/" === OFFERS_PAGE)
      dispatch(
        getOffers(
          {
            ...filters,
            sectors:
              data?.getSectors
                .filter((s) =>
                  filters.sectors?.find(
                    (sector) => sector === slugifyText(s.name)
                  )
                )
                .map((s) => s.name) ?? [],
          },
          currentPage,
          NUMBER_OF_ITEMS_PER_PAGINATION,
          companySlug
        )
      );
  };

  return (
    <OfferSearchContainer>
      <SelectorInput
        placeholder="Poste recherché"
        availableData={availableData.map((position) => position.name)}
        value={filters.title}
        onChange={(value) => {
          const newFilters = { ...filters, title: value };
          dispatch(OffersActions.addFilters(newFilters));
        }}
        width={isMedium ? "100%" : "50%"}
      />

      <OfferLocationInput width={isMedium ? "100%" : "25%"} />

      <DropdownInput
        scrollable
        placeholder={
          formatSelectedFilters(
            filters.contractTypes?.map(
              (contract) => contractTypeMapping[contract]
            )
          ) ?? "Type de contrat"
        }
        selected={!!formatSelectedFilters(filters.contractTypes)}
        width={isMedium ? "100%" : "25%"}
      >
        <ContractTypes
          setContractTypes={(contractTypes) => {
            const newFilters = { ...filters, contractTypes };
            dispatch(OffersActions.addFilters(newFilters));
          }}
          contractTypes={filters.contractTypes}
          size="small"
        />
      </DropdownInput>

      <FlatSvg
        onClick={submitSearch}
        color={version === 1 ? "var(--color-buttons)" : "var(--color-dark-1)"}
        width={isMedium ? "90%" : "180px"}
        margin={
          isMedium
            ? "10px auto"
            : version === 1
            ? "0 10px 0 0 5px"
            : "0 15px 0 0"
        }
      >
        <p>Rechercher</p>
      </FlatSvg>
    </OfferSearchContainer>
  );
};

export default OfferSearchInputs;
