import React from "react";
import styled from "styled-components";

const EticketContainer = styled.span<{
  color: string;
  backgroundColor: string;
  padding?: string;
}>`
  margin-left: 8px;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  font-family: var(--font-title-bold);
  padding: ${(props) => props.padding || "3px 7px 3px 7px"};
  display: inline-block;
  transform: rotate(3deg);
`;

interface Props {
  text: string;
  backgroundColor: string;
  color: string;
  padding?: string;
}

const Eticket: React.FC<Props> = ({
  text,
  color,
  backgroundColor,
  padding,
}) => {
  return (
    <EticketContainer
      color={color}
      backgroundColor={backgroundColor}
      padding={padding}
    >
      {text}
    </EticketContainer>
  );
};

export default Eticket;
