import gql from "../../apollo.setup";

export const GET_SECTORS = gql`
  query {
    getSectors {
      id
      name
    }
  }
`;
