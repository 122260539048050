import React, { useState } from "react";
import styled from "styled-components";

import useClickOutside from "../../hooks/useClickOutside";
import ArrowDown from "../assets/icons/ArrowDown";

interface DropdownInputContainerProps {
  width?: string;
  suggestionsWidth?: string;
  selected?: boolean;
  scrollable?: boolean;
}

const DropdownInputContainer = styled.div<DropdownInputContainerProps>`
  width: ${(props) => props.width ?? "100%"};
  position: relative;
  .dropdownInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
    height: 60px;
    @media screen and (max-width: 992px) {
      height: 50px;
    }
    border-radius: 10px;
    background-color: var(--color-fields);
    border: 3px solid var(--color-border);
    cursor: pointer;
  }

  .placeholder {
    font-size: 1rem;
    color: ${(props) =>
      props.selected ? "var(--color-primary)" : "var(--color-dark-2)"};
    font-family: var(--font-title-bold);
  }

  .suggestions {
    padding: 10px;
    width: ${(props) => props.suggestionsWidth ?? "100%"};
    background-color: white;
    border: 3px solid var(--color-border);
    border-radius: 10px;
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    z-index: 10;
    max-height: 200px;
    ${(props) => props.scrollable && "overflow-y: scroll;"}
  }
`;

interface Props extends DropdownInputContainerProps {
  children: React.ReactNode;
  placeholder?: string;
  hasToggle?: boolean;
  onClose?: () => void;
}

const DropdownInput: React.FC<Props> = ({
  children,
  placeholder,
  width,
  suggestionsWidth,
  selected,
  hasToggle = true,
  scrollable = false,
  onClose,
}) => {
  const [openToggle, setOpenToggle] = useState(false);
  const toggleRef = React.useRef(null);
  const containerRef = React.useRef(null);
  useClickOutside(
    toggleRef,
    containerRef,
    () => setOpenToggle(true),
    () => {
      if (onClose) onClose();
      setOpenToggle(false);
    }
  );

  return (
    <DropdownInputContainer
      suggestionsWidth={suggestionsWidth}
      width={width}
      selected={selected}
      scrollable={scrollable}
    >
      <div ref={toggleRef} className="dropdownInput">
        <p className="placeholder">{placeholder}</p>
        {hasToggle && (
          <ArrowDown
            width={15}
            height={15}
            color={"var(--color-primary)"}
            style={{
              transform: openToggle ? "rotate(180deg)" : "rotate(0deg)",
              transition: "all 0.3s ease-in-out",
            }}
          />
        )}
      </div>

      {openToggle && (
        <div ref={containerRef} className="suggestions">
          {children}
        </div>
      )}
    </DropdownInputContainer>
  );
};

export default DropdownInput;
