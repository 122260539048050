import styled from "styled-components";

interface RoundedSvgProps {
  width?: string;
  height?: string;
  color: string;
  clickable?: boolean;
}

interface FlatSvgProps {
  width?: string;
  height?: string;
  color: string;
  margin?: string;
  border?: string;
}

export const RoundedSvg = styled.div<RoundedSvgProps>`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  .lazy-load-image-background {
    img {
      width: 100%;
    }
  }

  /* large  */
  @media screen and (max-width: 992px) {
    width: ${(props) => props.width ?? "45px"};
    height: ${(props) => props.height ?? "45px"};
  }
  background-color: ${(props) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  @media screen and (max-width: 768px) {
    margin-right: 0;
  }
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
`;

export const FlatSvg = styled.div<FlatSvgProps>`
  border-radius: 7px;
  width: ${(props) => props.width ?? "130px"};
  height: ${(props) => props.height ?? "60px"};
  background-color: ${(props) => props.color};
  border: ${(props) => props.border ?? "0"};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props) => props.margin ?? "0 15px 0 0"};
  cursor: pointer;

  span {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  p {
    color: white;
    font-family: var(--font-text);
    font-weight: bold;
  }
`;
