import React from "react";

import { ContractType } from "../../../../types/select.type";
import {
  contractTypeKeys,
  contractTypeMapping,
} from "../../../../utils/mapping";
import styles from "../ProfileSettings.module.scss";

interface Props {
  contractTypes?: ContractType[];
  label?: string;
  size?: "small" | "large" | "xlarge" | "fit";
  isError?: boolean;
  setContractTypes: (contractTypes: ContractType[]) => void;
}

const ContractTypes: React.FC<Props> = ({
  contractTypes,
  label,
  size = "large",
  isError,
  setContractTypes,
}) => {
  const handleToggleContractType = (contractType: ContractType) => {
    if (contractTypes?.includes(contractType)) {
      // should remove the contract type element from the array
      setContractTypes(contractTypes.filter((ct) => ct !== contractType));
    } else {
      // should add the contract type element to the array
      if (contractTypes) setContractTypes([...contractTypes, contractType]);
      else setContractTypes([contractType]);
    }
  };

  return (
    <div
      className={`${
        size === "large"
          ? styles.contract_types_container
          : size === "small"
          ? styles.contract_types_small_container
          : size === "fit"
          ? styles.contract_types_fit_container
          : styles.contract_types_xlarge_container
      }`}
    >
      {label && <h4 className={styles.contract_types_label}>{label}</h4>}
      <div
        className={`${styles.contract_types_content} ${
          isError && styles.required
        }`}
      >
        {contractTypeKeys.map((contractType) => (
          <div
            key={contractType}
            className={
              contractTypes?.includes(contractType)
                ? styles.active
                : styles.inactive
            }
            onClick={() => handleToggleContractType(contractType)}
          >
            {contractTypeMapping[contractType]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContractTypes;
