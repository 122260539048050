import React, { useEffect, useState } from "react";
import styled from "styled-components";

const SelectorInputContainer = styled.div<DropdownInputContainerProps>`
  width: ${(props) => props.width ?? "100%"};
  position: relative;
  color: var(--color-dark-1);
  font-family: var(--font-title-bold);

  input {
    width: 100%;
    height: 60px;
    padding: 20px;
    background-color: ${(props) =>
      props.error
        ? "var(--color-red-light)"
        : props.disabled
        ? " var(--color-fields-disabled)"
        : " var(--color-fields)"};
    border: ${(props) =>
      props.error
        ? "3px solid var(--color-red)"
        : props.disabled
        ? "3px solid var(--color-border-disabled)"
        : "3px solid var(--color-border)"};
    outline: none;
    border-radius: 10px;
    font-family: var(--font-title-bold);
    color: var(--color-primary);
    font-size: 1rem;

    &::placeholder {
      font-size: 1rem;
      color: var(--color-dark-2);
      font-family: var(--font-title-bold);
    }

    @media screen and (max-width: 992px) {
      height: 50px;
    }
  }

  label {
    display: block;
    font-size: 1rem;
    margin: auto auto 10px 10px;
    font-family: var(--font-title-bold);
  }

  .suggestions {
    width: ${(props) => props.suggestionsWidth ?? "100%"};
    background-color: white;
    border: ${(props) =>
      props.error
        ? "3px solid var(--color-red)"
        : "3px solid var(--color-border)"};
    border-radius: 10px;
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    z-index: 10;
    max-height: 200px;
    overflow-y: scroll;

    .suggestion {
      padding: 10px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: var(--color-dark-1);
        color: white;
      }
    }
  }
`;
interface DropdownInputContainerProps {
  width?: string;
  suggestionsWidth?: string;
  error?: boolean;
  disabled?: boolean;
}

interface Props extends DropdownInputContainerProps {
  placeholder?: string;
  availableData: string[];
  value?: string;
  className?: string;
  label?: string;
  onChange: (value: string) => void;
  onSelect?: (value: string) => void;
  disabled?: boolean;
}

const SelectorInput: React.FC<Props> = ({
  error,
  placeholder,
  value,
  availableData,
  width,
  suggestionsWidth,
  label,
  className,
  onChange,
  onSelect,
  disabled,
}) => {
  const [openToggle, setOpenToggle] = useState(false);
  const toggleRef = React.useRef<HTMLInputElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    // if the user toggle outside the container and the toggle
    // is open, we close it
    const handleClickOutside = (e: MouseEvent) => {
      const target = e.target as Node;
      if (
        containerRef.current &&
        !containerRef.current.contains(target) &&
        toggleRef.current &&
        !toggleRef.current.contains(target)
      ) {
        setOpenToggle(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Unbind the event listener on clean up
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleRef.current, containerRef.current]);

  return (
    <SelectorInputContainer
      className={className}
      suggestionsWidth={suggestionsWidth}
      width={width}
      error={error}
      disabled={disabled}
    >
      {label && <label>{label}</label>}
      <input
        placeholder={placeholder}
        ref={toggleRef}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onClick={() => {
          setOpenToggle(true);
        }}
        disabled={disabled}
      />
      {openToggle && availableData.length > 0 && (
        <div ref={containerRef} className="suggestions">
          {availableData.map((option) => (
            <div
              className="suggestion"
              key={option}
              onClick={() => {
                onChange(option);
                if (onSelect) onSelect(option);
                setOpenToggle(false);
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </SelectorInputContainer>
  );
};

export default SelectorInput;
