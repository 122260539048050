import axios, { AxiosRequestHeaders } from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { OffersActions } from "../../../../redux/offers/offers.slice";
import { Dispatch, StoreState } from "../../../../redux/store";
import SelectorInput from "../../../Input/SelectorInput";

type Headers =
  | (AxiosRequestHeaders & {
      common?: { Authorization?: string };
    })
  | undefined;

interface Props {
  width?: string;
  suggestionsWidth?: string;
}

const OfferLocationInput: React.FC<Props> = ({ width, suggestionsWidth }) => {
  const [availableData, setAvailableData] = useState<string[]>([]);
  const [keyword, setKeyword] = useState("");

  const filters = useSelector((store: StoreState) => store.offers.filters);
  const dispatch = useDispatch<Dispatch>();

  const getLocations = async () => {
    const hereApiKey = process.env.NEXT_PUBLIC_HERE_API;
    const country = "FRA";
    const res = await axios.get(
      `https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?apiKey=${hereApiKey}&query=${keyword}&country=${country}&language=fr`,
      {
        transformRequest: (data, headers: Headers) => {
          if (headers?.common?.Authorization)
            delete headers?.common.Authorization;
          return data;
        },
      }
    );

    // get the data from the here api
    const suggestions: string[] = [];
    if (res.data.suggestions) {
      res.data.suggestions.forEach(
        (suggestion: {
          address: { city?: string; state?: string; district?: string };
        }) => {
          if (
            suggestion.address.city &&
            !suggestions.includes(suggestion.address.city)
          )
            suggestions.push(suggestion.address.city);

          if (
            suggestion.address.state &&
            !suggestions.includes(suggestion.address.state)
          )
            suggestions.push(suggestion.address.state);

          if (
            suggestion.address.district &&
            !suggestions.includes(suggestion.address.district)
          )
            suggestions.push(suggestion.address.district);
        }
      );
    }
    setAvailableData(suggestions);
  };

  useEffect(() => {
    getLocations();
  }, [keyword]);

  return (
    <SelectorInput
      placeholder="Nom de l’adresse"
      availableData={availableData}
      value={filters.cities?.[0]}
      onChange={(value) => {
        setKeyword(value);
        const newFilters = { ...filters, cities: value === "" ? [] : [value] };
        dispatch(OffersActions.addFilters(newFilters));
      }}
      width={width}
      suggestionsWidth={suggestionsWidth}
    />
  );
};

export default OfferLocationInput;
