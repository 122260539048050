import gql from "../../apollo.setup";

export const GET_POSITIONS = gql`
  query {
    getPositions {
      id
      name
    }
  }
`;

export const GET_OFFER_POSITIONS = gql`
  query {
    getOfferPositions {
      id
      name
    }
  }
`;

export const GET_POSITION_BY_SLUG = gql`
  query GetPositionBySlug($slug: String!) {
    getPositionBySlug(slug: $slug) {
      id
      name
      diploma
      missions
      evolution
      salary
      jobSeeker
      recruitWord
      slug
    }
  }
`;
